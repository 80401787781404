import { useEffect, useState } from "react";

import { getProducts } from "../../../api/products";
import {
  BillingPeriod,
  ErrorResponse,
  ProductCode,
  ProductDto,
  ProductsResponse,
} from "../../../api/types";
import Button from "../layout/utils/Button";

export type PlanDetails = {
  productCode: ProductCode;
  billingPeriod: BillingPeriod;
};

const PricingPlans = ({
  onPlanChanged,
  onPlanFetchingFailed,
  className,
  buttonText,
  currentPlanDetails,
}: {
  onPlanChanged: (planDetails: PlanDetails) => void;
  onPlanFetchingFailed?: (errorResponse: ErrorResponse) => void;
  className?: string;
  buttonText: string;
  currentPlanDetails?: PlanDetails;
}) => {
  const [annualPlanSelected, setAnnualPlanSelected] = useState<boolean>(true);
  const [products, setProducts] = useState<ProductDto[] | null>(null);

  useEffect(() => {
    getProducts()
      .then((productsResponse: ProductsResponse) => {
        setProducts(productsResponse.products);
      })
      .catch((errorResponse: ErrorResponse) => {
        if (onPlanFetchingFailed) {
          onPlanFetchingFailed(errorResponse);
        }
      });
  }, [onPlanFetchingFailed]);

  useEffect(() => {
    setAnnualPlanSelected(isAnnualBillingSelected());
  }, [currentPlanDetails]);

  function isAnnualBillingSelected(): boolean {
    if (currentPlanDetails?.billingPeriod) {
      return currentPlanDetails.billingPeriod === BillingPeriod.YEARLY;
    }

    return true;
  }

  function handleBillingPeriodSwitched(newValue: boolean) {
    setAnnualPlanSelected(newValue);
  }

  function getHightlightedText(): string {
    if (currentPlanDetails) return "Current plan";

    return "Most popular";
  }

  function isHighlighted(product: ProductDto): boolean {
    if (currentPlanDetails?.productCode)
      return currentPlanDetails.productCode === product.code;

    return product.code == ProductCode.STANDARD;
  }

  function getMonthlyPriceOnAnnualPlan(product: ProductDto): number {
    let monthlyPrice = product.yearlyPrice / 12;

    return Math.round(monthlyPrice * 100) / 100;
  }

  function getPrice(product: ProductDto): number {
    return annualPlanSelected
      ? getMonthlyPriceOnAnnualPlan(product)
      : product.monthlyPrice;
  }

  function getPricingDetails(product: ProductDto): string {
    if (product.code == ProductCode.FREE) {
      return "Always free";
    } else if (annualPlanSelected) {
      return "per month, $" + product.yearlyPrice + " billed annually";
    } else {
      return (
        "$" +
        getMonthlyPriceOnAnnualPlan(product) +
        " per month if billed annually"
      );
    }
  }

  function getBillingPeriod(): BillingPeriod {
    return annualPlanSelected ? BillingPeriod.YEARLY : BillingPeriod.MONTHLY;
  }

  function getFeatures(product: ProductDto): string[] {
    return [
      product.monthlyUsageLimit.toLocaleString() + " conversions per month",
      "Unlimited seats",
      "Parallel processing",
      "Email and chat support",
    ];
  }

  function handlePlanSelected(productCode: ProductCode): void {
    onPlanChanged({
      productCode,
      billingPeriod: getBillingPeriod(),
    });
  }

  return (
    <div className={`mt-10 ${className || ""}`}>
      <div className='flex justify-center items-center pt-10'>
        <label className='min-w-[3.5rem] text-sm text-gray-500 mr-3'>
          Monthly
        </label>

        <input
          type='checkbox'
          id='hs-basic-with-description'
          className='relative shrink-0 w-[3.25rem] h-7 bg-gray-200 checked:bg-none checked:bg-blue-600 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 border border-transparent ring-1 ring-transparent focus:border-blue-600 focus:ring-blue-600 ring-offset-white focus:outline-none appearance-none dark:bg-gray-700 dark:checked:bg-blue-600 dark:focus:ring-offset-gray-800 before:inline-block before:w-6 before:h-6 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:shadow before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200'
          checked={annualPlanSelected}
          onChange={() => handleBillingPeriodSwitched(!annualPlanSelected)}
        />

        <label className='relative min-w-[3.5rem] text-sm text-gray-500 ml-3'>
          Annual
          <span className='absolute -top-10 left-auto -right-16 sm:-right-28'>
            <span className='flex items-center'>
              <svg
                className='w-14 h-8 -mr-16 sm:-mr-6'
                width='45'
                height='25'
                viewBox='0 0 45 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M43.2951 3.47877C43.8357 3.59191 44.3656 3.24541 44.4788 2.70484C44.5919 2.16427 44.2454 1.63433 43.7049 1.52119L43.2951 3.47877ZM4.63031 24.4936C4.90293 24.9739 5.51329 25.1423 5.99361 24.8697L13.8208 20.4272C14.3011 20.1546 14.4695 19.5443 14.1969 19.0639C13.9242 18.5836 13.3139 18.4152 12.8336 18.6879L5.87608 22.6367L1.92723 15.6792C1.65462 15.1989 1.04426 15.0305 0.563943 15.3031C0.0836291 15.5757 -0.0847477 16.1861 0.187863 16.6664L4.63031 24.4936ZM43.7049 1.52119C32.7389 -0.77401 23.9595 0.99522 17.3905 5.28788C10.8356 9.57127 6.58742 16.2977 4.53601 23.7341L6.46399 24.2659C8.41258 17.2023 12.4144 10.9287 18.4845 6.96211C24.5405 3.00476 32.7611 1.27399 43.2951 3.47877L43.7049 1.52119Z'
                  fill='currentColor'
                  className='fill-gray-300'
                />
              </svg>
              <span className='-mt-10 sm:mt-3 inline-block whitespace-nowrap text-[11px] leading-5 font-semibold tracking-wide uppercase bg-blue-600 text-white rounded-full py-1 px-2.5'>
                Save up to 20%!
              </span>
            </span>
          </span>
        </label>
      </div>

      <div className='mt-8 grid sm:grid-cols-2 lg:grid-cols-4 gap-6 lg:items-center'>
        {products?.map((product: ProductDto) => (
          <div
            className={`flex flex-col border ${
              isHighlighted(product)
                ? "border-blue-600 border-2"
                : "border-gray-200"
            } text-center rounded-xl p-8`}
            key={product.code}
          >
            {isHighlighted(product) ? (
              <p className='mb-3'>
                <span className='inline-flex items-center gap-1.5 py-1.5 px-3 rounded-md text-xs uppercase font-semibold bg-blue-100 text-blue-800 dark:bg-blue-600 dark:text-white'>
                  {getHightlightedText()}
                </span>
              </p>
            ) : (
              ""
            )}
            <h4 className='font-medium text-lg text-gray-800'>
              {product.code}
            </h4>
            <span className='mt-7 font-bold text-5xl text-gray-800'>
              <span className='font-bold text-2xl mr-1'>$</span>
              {getPrice(product)}
            </span>
            <p className='mt-1 text-sm text-gray-500'>
              {getPricingDetails(product)}
            </p>

            <ul className='mt-7 space-y-2.5 text-sm'>
              {getFeatures(product).map((feature: string) => (
                <li key={product + feature} className='flex space-x-2'>
                  <svg
                    className='flex-shrink-0 h-5 w-5 text-blue-600'
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M11.5219 4.0949C11.7604 3.81436 12.181 3.78025 12.4617 4.01871C12.7422 4.25717 12.7763 4.6779 12.5378 4.95844L6.87116 11.6251C6.62896 11.91 6.1998 11.94 5.9203 11.6916L2.9203 9.02494C2.64511 8.78033 2.62032 8.35894 2.86493 8.08375C3.10955 7.80856 3.53092 7.78378 3.80611 8.02839L6.29667 10.2423L11.5219 4.0949Z'
                      fill='currentColor'
                    />
                  </svg>
                  <span className='text-gray-800 text-left'>{feature}</span>
                </li>
              ))}
            </ul>

            <Button
              id={`landing-pricing-plan-${product.code}`}
              type='button'
              name={product.code}
              value={product.code}
              className='mt-5'
              text={buttonText}
              size='large'
              color={
                isHighlighted(product) ? "secondary" : "white-secondary-border"
              }
              onClick={() => handlePlanSelected(product.code)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingPlans;
